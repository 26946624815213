import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
// cmp
import Svg from "../svg";
// services
import { icons } from "@local/theme";
// types
import type { DeviceType } from "../../types/device-type";
import type { EpDevice } from "../../types/device";
import type { AttributeId } from "../../types/cluster";
import type { RelativePathPrefix } from "../../types/misc";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"000C" | "0400" | "0402" | "0403" | "0405" | "FD01" | "FD02" | "FD03" | "FD04" | "FF81" | "FF94" | "FF95" | "FF9F">;
	relativePathPrefix: RelativePathPrefix;
	showFull: boolean;
};

const DXXXX = (props: Props) => {
	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);

	if (props.showFull) {
		return (
			<ListItemButton
				component={Link}
				to={`${props.relativePathPrefix}graph/${props.deviceType.clusterId}/${props.deviceType.attributeId as AttributeId}`}
			>
				<ListItemIcon><Svg src={props.deviceType.getIcon()} /></ListItemIcon>
				<ListItemText primary={props.deviceType.getTitle(props.epDevice.stringOverrideKey)} />
				<ListItemText primary={props.deviceType.formatValue(cluster)} />
				<ListItemIcon><icons.ChevronRight /></ListItemIcon>
			</ListItemButton>
		);
	}

	return null;
};

DXXXX.defaultProps = {
	showFull: false,
};

DXXXX.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		attributeId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
		getTitle: PropTypes.func.isRequired,
		getIcon: PropTypes.func.isRequired,
		formatValue: PropTypes.func.isRequired,
	}).isRequired,
	relativePathPrefix: PropTypes.string.isRequired,
	showFull: PropTypes.bool,
};

export default DXXXX;
