import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
// services
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { Storage, StorageKeys } from "../../services/storage";
import { icons } from "@local/theme";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType as DeviceTypeT } from "../../types/device-type";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceTypeT<"F1F0">;
};

const DF1F0 = (props: Props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);

	if (cluster === undefined) {
		return;
	}

	const isParent = cluster[props.deviceType.attributeId] === Constants.SubDeviceType.Parent;

	const handleNavigationClick = () => {
		if (isParent) {
			navigate("./subdevices");
		} else {
			const parentDeviceId = `${cluster[ClusterConstants.DF1F0.Attributes.ParentsGatewayId]}|${cluster[ClusterConstants.DF1F0.Attributes.ParentsDeviceId]}`;
			Storage.set(StorageKeys.accountDeviceId, parentDeviceId);
		}
	};

	return (
		<ListItemButton onClick={handleNavigationClick}>
			<ListItemText primary={t(isParent ? "clusters.DF1F0.title" : "clusters.DF1F0.backToMainDevice")} />
			<ListItemIcon><icons.ChevronRight /></ListItemIcon>
		</ListItemButton>
	);
};

DF1F0.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		attributeId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
	}).isRequired,
};

export default DF1F0;
