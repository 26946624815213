import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ListItem, ListItemText } from "@mui/material";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"FF92">;
	showFull?: boolean;
};

const DFF92 = (props: Props) => {
	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);

	if (props.showFull) {
		return (
			<ListItem>
				<ListItemText primary={t("clusters.DFF92.notification")} />
				<ListItemText primary={props.deviceType.formatValue(cluster)} />
			</ListItem>
		);
	}
	return null;
};

DFF92.defaultProps = {
	showFull: false,
};

DFF92.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
		formatValue: PropTypes.func.isRequired,
	}).isRequired,
	showFull: PropTypes.bool,
};

export default DFF92;
