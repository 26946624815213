import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ListItemText, ListItem } from "@mui/material";
// cmp
import ListItemContainer from "../ListItemContainer";
import CustomSwitch from "../custom-switch";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendGeneralCmdWrite } from "../../types/message";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"FF85">;
};

const DFF85 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);
	const attributeId = cluster.hasOwnProperty(ClusterConstants.DFF85.Attributes.LEDStatusWalliOutlet)
		? ClusterConstants.DFF85.Attributes.LEDStatusWalliOutlet
		: ClusterConstants.DFF85.Attributes.LEDStatus;

	const [toggle, setToggle] = useDynamicUpdateState(Boolean(props.deviceType.entries[attributeId].formatValue(cluster)));

	const handleToggle = (isInputChecked: boolean) => {
		setToggle(isInputChecked);

		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: attributeId,
				datatype: Constants.DataType.UInt8Bit,
				value: isInputChecked
					? (attributeId === ClusterConstants.DFF85.Attributes.LEDStatusWalliOutlet)
						? ClusterConstants.DFF85.CmdPayloads.ColorByPower
						: ClusterConstants.DFF85.CmdPayloads.ColorByTemperature
					: ClusterConstants.DFF85.CmdPayloads.LEDDisabled,
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"FF85">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	return (
		<>
			<ListItem>
				<ListItemText
					primary={t("clusters.DFF85.title")}
					secondary={(attributeId === ClusterConstants.DFF85.Attributes.LEDStatus) ? t("clusters.DFF85.subTitle") : null}
				/>
				<ListItemContainer><CustomSwitch checked={toggle} onChange={handleToggle} /></ListItemContainer>
			</ListItem>
			<Toast
				autoHideDuration={6000}
				severity="error"
				open={showGenericErrorMsg}
				onClose={setShowGenericErrorMsg}
				message={t("toast.genericErrorMsg")}
			/>
		</>
	);
};

DFF85.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
		entries: PropTypes.shape({
			[ClusterConstants.DFF85.Attributes.LEDStatus]: PropTypes.shape({
				formatValue: PropTypes.func.isRequired,
			}).isRequired,
			[ClusterConstants.DFF85.Attributes.LEDStatusWalliOutlet]: PropTypes.shape({
				formatValue: PropTypes.func.isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
};

export default DFF85;
